import { BaseElement, html, css } from 'Elements';

import nodeTypes from './types.js';

class RegistryTreeContextMenu extends BaseElement {
  static get styles() {
    return [
      css`

      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object },
      floating: { type: Boolean },
      offsetX: { type: Number },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            add:'Add',
            remove:'Delete',
            edit:'Edit',
            archive:'Archive',
            restore:'Restore',
          },
        },
        french:{
          translation: {
            add:'Ajouter',
            remove:'Supprimer',
            edit:'Editer',
            archive:'Archiver',
            restore:'Restaurer',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.debug = false;
    this.firstTime = true;
    this.offsetX = 0;
    this.nodeTypes = nodeTypes.getNodeTypes();    
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.show = this.show.bind(this);
  }

  shouldUpdate() {
    if (this.firstTime) {
      this.firstTime = false;
      return true;
    }
    
    return false;
  }

  async updated(changedProperties) {
    this._log.debug('updated: changedProperties', changedProperties);
    if (changedProperties.has('parent')) {
      this.showAddItem = this.parent.showAddItem.bind(this.parent);
      this.showEditItem = this.parent.showEditItem.bind(this.parent);
      this.showConfirmRestore = this.parent.showConfirmRestore.bind(this.parent);
      this.showConfirmDelete = this.parent.showConfirmDelete.bind(this.parent);
      this.showConfirmArchive = this.parent.showConfirmArchive.bind(this.parent);
    }
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('language-changed', this.onLanguageChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-changed', this.onLanguageChanged);
  }

  onLanguageChanged() {
    this.nodeTypes = nodeTypes.getNodeTypes();
    this.requestUpdate();
  }

  renderContextMenu(isContextMenu) {
    const renderedSubgroups = {};  // Pour suivre les sous-groupes déjà rendus

    if (!this.nodeTypes) return;

    let selectedNodeType = null;
    selectedNodeType = this.selectedItem ? this.nodeTypes[this.selectedItem.nodeType] : null;
    this._log.debug('renderContextMenu: selectedNodeType',selectedNodeType);

    const inTrash = this.selectedTreeItem?.parentElement?.item?.nodeType === 'trash';
    const isTrash = this.selectedTreeItem?.item?.nodeType === 'trash';

    if (isTrash) {
      return;
    }

    let menuItems = [];   
    
    // Filtrer les noeuds en fonction du sous-groupe sélectionné
    //let filteredNodes = this.nodeTypes;

    /*
    if (selectedNodeType) {
      filteredNodes = {};
      for (const [id, nodeType] of Object.entries(this.nodeTypes)) {
        if (selectedNodeType.submenus_excluded?.includes(id) === false) {
          filteredNodes[id] = nodeType;
        }
      }
    }
    */

    if (!this.hasRoot) {
      const node = this.nodeTypes['root'];
      menuItems.push(html`
        <sl-menu-item value="root" @click="${() => this.showAddItem('root')}">
          <m-icon slot="prefix" name="${node.micon}"></m-icon>
          ${node.name}
        </sl-menu-item>
      `);
    }

    if (this.hasRoot && !selectedNodeType?.noChilds && !inTrash) {

      menuItems = Object.entries(this.nodeTypes).map(([id, nodeType]) => {
        if (id === 'root'&& this.hasRoot) return '';
        const disabled = (id !== 'root' && !this.hasRoot);
        if (nodeType.subgroup) {
          // Vérifie si le sous-groupe a déjà été rendu
          if (!renderedSubgroups[nodeType.subgroup]) {
            renderedSubgroups[nodeType.subgroup] = [];
            if (nodeType.menuHide) {
              return '';
            }
            return html`
              <sl-menu-item ${disabled}>
                <m-icon slot="prefix" name="${nodeType.micon}"></m-icon>
                ${nodeType.subgroup}
                <sl-menu slot="submenu">
                  ${Object.entries(this.nodeTypes).map(([subId, subNodeType]) => {
                    if (subNodeType.subgroup === nodeType.subgroup) {
                      return html`
                        <sl-menu-item value="${subId}" @click="${() => this.showAddItem(subId)}">
                          <m-icon slot="prefix" name="${subNodeType.micon}"></m-icon>
                          ${subNodeType.name}
                        </sl-menu-item>
                      `;
                    }
                  })}
                </sl-menu>
              </sl-menu-item>
            `;
          }
        } else {
          // Si le nodeType n'a pas de sous-groupe, l'ajouter directement

          if (nodeType.menuHide) {
            return '';
          }
            
          return html`
            <sl-menu-item ${disabled} value="${id}" @click="${() => this.showAddItem(id)}">
              <m-icon slot="prefix" name="${nodeType.micon}"></m-icon>
              ${nodeType.name}
            </sl-menu-item>
          `;
        }
      })
    }

    const children = this.selectedTreeItem?.querySelectorAll('sl-tree-item') || [];

    if (isContextMenu) {
      return html`
        ${this.hasRoot && !inTrash
          ? html`
            <sl-menu-item ?disabled=${!menuItems.length}>
              <m-icon slot="prefix" name="add"></m-icon>
              ${this._tl('add')}
              <sl-menu slot="submenu">${menuItems}</sl-menu>
            </sl-menu-item>`
          : menuItems
        }

        ${this.hasRoot && !inTrash
          ? html`
              <sl-menu-item @click=${() => this.showEditItem()}>
                <m-icon slot="prefix" name="edit"></m-icon>
                ${this._tl('edit')}
              </sl-menu-item>`
          : ''
        }

        ${this.hasRoot && !children.length
          ? html`
            ${inTrash
              ? html`
                <sl-menu-item @click=${(ev) => this.showConfirmRestore(ev)}>
                  <m-icon slot="prefix" name="restore_from_trash"></m-icon>
                  ${this._tl('restore')}
                </sl-menu-item>
                <sl-divider></sl-divider>
                <sl-menu-item @click=${(ev) => this.showConfirmDelete(ev)} class="delete">
                  <m-icon slot="prefix" name="delete_forever"></m-icon>
                  ${this._tl('remove')}
                </sl-menu-item>
              `
              : html`
                <sl-divider></sl-divider>
                <sl-menu-item @click=${(ev) => this.showConfirmArchive(ev)} class="delete" ?disabled="${ENV !== 'dev' && this.selectedItem.nodeType === 'root'}">
                  <m-icon slot="prefix" name="delete"></m-icon>
                  ${this._tl('archive')}
                </sl-menu-item>`
            }`
          : ''
        }
      `
    }

    this._log.debug('renderContextMenu: menuItems', menuItems);

    return menuItems;
  }

  async show(ev) {
    if (ev.preventDefault) ev.preventDefault();
    if (ev.stopPropagation) ev.stopPropagation();

    this.selectedItem = this.parent.selectedItem;
    this.selectedTreeItem = this.parent.selectedTreeItem;
    this.hasRoot = this.parent.hasRoot;

    this.contextMenu = this.contextMenu || this.qs('context-menu');
    if (!this.contextMenu) {
      console.error('context-menu not found');
      return;
    }

    this.contextMenu.content = html`
      <sl-menu>
        <!-- <sl-menu-item>${this.selectedItem?.name}</sl-menu-item> -->
        ${this.renderContextMenu(true)}
      </sl-menu>
    `;
    
    this._log.debug('show', ev.clientX, ev.clientY, this.contextMenu);
    this.contextMenu.show(ev.clientX, ev.clientY);
  }

  getButtonAddStatus() {
    if (!this.selectedItem) return false;
    if (this.nodeTypes[this.selectedItem.nodeType].noChilds) return true;
    return false;
  }

  firstUpdated() {
    this._log.debug('firstUpdated', this.floating);
    if (!this.floating) {
      if (this.parentElement.tagName === 'SL-DROPDOWN') {
        this.dropdown = this.parentElement;
        this.button = this.dropdown.querySelector('sl-button');
        this.dropdown.addEventListener('click', ev => { ev.stopPropagation(); });
        this.dropdown.addEventListener('sl-show', () => {
          const rect = this.button.getBoundingClientRect();
          //const x = rect.left;  // Coordonnée X par rapport à la fenêtre
          //const y = rect.top;   // Coordonnée Y par rapport à la fenêtre
          const clientX = Math.round(rect.left + window.scrollX + this.offsetX);
          const clientY = Math.round(rect.top + rect.height + window.scrollY);
          this.show({ clientX, clientY });
          this.dropdown.reposition();
        });
      }
    }
  }

  render() {
    this._log.debug('render', this.floating);
    return html`
      <context-menu></context-menu>
    `;
  }
}

customElements.define('registry-tree-menu', RegistryTreeContextMenu);